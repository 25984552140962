import {computed, Ref} from '@vue/composition-api';
import {Point} from '@/common/types/Point';
import {sortBy} from 'lodash';

export interface UseReportSheetRowsOptions<
  X extends string | number | symbol,
  Y extends string | number | symbol,
> {
  x: X;
  y: Y;
  rows: Ref<Row<X | Y>[]>;
  rowFactory: () => Row<X | Y>;
}

export type Row<Keys extends string | number | symbol> = {
  [key in Keys]: null | number;
};

export function useReportSheetRows<
  X extends string | number | symbol,
  Y extends string | number | symbol,
>({x, y, rows, rowFactory}: UseReportSheetRowsOptions<X, Y>) {
  const convertToFloat = (value: string | number | null) => {
    if (value === null || typeof value === 'number') {
      return value;
    }

    if (value === '') {
      return null;
    }

    return parseFloat(value);
  };

  const numericRows = computed(() => {
    return rows.value.map((row) => {
      return {
        ...row,
        [x]: convertToFloat(row[x]),
        [y]: convertToFloat(row[y]),
      } as Row<X | Y>;
    });
  });

  const sortedRows: Ref<Point[]> = computed(() => {
    const points = numericRows.value
      .map((run: any) => {
        return {
          x: run[x],
          y: run[y],
        };
      })
      .filter((point): point is Point => !!point.x && !!point.y);

    return sortBy(points, 'x');
  });

  function addRun() {
    rows.value.push(rowFactory());
  }

  function removeRun(index?: number) {
    rows.value.splice(index ?? rows.value.length - 1, 1);
  }

  return {
    sortedRows,
    addRun,
    removeRun,
  };
}
