import {computed, Ref} from '@vue/composition-api';
import {useApexScatterPlot} from '@/tasks/composables/useApexScatterPlot';
import {useReportSheetRows} from '@/tasks/composables/useReportSheetRows';

export interface UseMcMasterLab6PlotOptions<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
> {
  labels: Labels;
  x: X;
  y: Y;
  rows: Ref<Row<keyof Labels>[]>;
  minY: number | null;
  maxY: number | null;
  minX: number | null;
  maxX: number | null;
}

export type Row<Keys extends string | number | symbol> = {
  [key in Keys]: null | number;
};

// FIXME: We cannot get better typing on inputs while using 'inputsDataProp'
export function useMcMasterLab6Plot<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
>({labels, x, y, rows, minY, maxY, minX, maxX}: UseMcMasterLab6PlotOptions<Labels, X, Y>) {
  // @ts-ignore
  const {addRun, removeRun} = useReportSheetRows({
    x,
    y,
    rows,
    // @ts-expect-error
    rowFactory: () => ({[x]: null, [y]: null}),
  });
  const convertToFloat = (value: string | number | null) => {
    if (value === null || typeof value === 'number') {
      return value;
    }

    if (value === '') {
      return null;
    }

    return parseFloat(value);
  };

  const numericRows = computed(() => {
    return rows.value.map((row) => {
      return {
        ...row,
        [x]: convertToFloat(row[x]),
        [y]: convertToFloat(row[y]),
      } as Row<keyof Labels>;
    });
  });

  const {apexOptions, apexSeries} = useApexScatterPlot({
    rows: numericRows,
    labels,
    x,
    y,
    minY,
    maxY,
    minX,
    maxX,
  });

  return {
    apexSeries,
    apexOptions,
    addRun,
    removeRun,
  };
}
