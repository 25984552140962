import {computed, Ref} from '@vue/composition-api';
import {Point} from '@/common/types/Point';
import {maxBy, minBy, sortBy} from 'lodash';
import {calculateAxisMinMaxValue} from '@/common/utils/graphs';

export interface UseApexScatterPlotOptions<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
> {
  labels: Labels;
  x: X;
  y: Y;
  rows: Ref<Row<keyof Labels>[]>;
  minY?: number | null;
  maxY?: number | null;
  minX?: number | null;
  maxX?: number | null;
  predictFn?: (x: number) => number | null;
}

export type Row<Keys extends string | number | symbol> = {
  [key in Keys]: null | number;
};

export function useApexScatterPlot<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
>({
  labels,
  x,
  y,
  rows,
  minY,
  maxY,
  minX,
  maxX,
  predictFn,
}: UseApexScatterPlotOptions<Labels, X, Y>) {
  const plotPoints: Ref<Point[]> = computed(() => {
    const points = rows.value
      // @ts-ignore -- TS2590: Expression produces a union type that is too complex to represent.
      ?.filter((point) => !!point[x] && !!point[y])
      .map((run: any) => {
        return {
          x: run[x],
          y: run[y],
        };
      });

    return sortBy(points, 'x');
  });

  const regressionLine: Ref<Point[]> = computed(() =>
    plotPoints.value
      .map(({x}) => {
        return {x, y: predictFn?.(x)};
      })
      .filter((point): point is {x: number; y: number} => point.y !== null)
  );

  const minYAxis = computed(
    () => minY ?? calculateAxisMinMaxValue(minBy(rows.value, y)?.y ?? -10, false)
  );

  const maxYAxis = computed(() => maxY ?? calculateAxisMinMaxValue(maxBy(rows.value, y)?.[y] ?? 5));
  const minXAxis = computed(() => minX ?? 0);

  const maxXAxis = computed(
    () => maxX ?? calculateAxisMinMaxValue(maxBy(rows.value, x)?.[x] ?? 100)
  );

  const apexSeries = computed(() => {
    const series = [
      {
        name: labels[y] as string,
        type: 'scatter',
        data: plotPoints.value,
      },
    ];

    if ((regressionLine.value ?? []).length >= 2) {
      series.push({
        name: 'Line of Best Fit',
        type: 'line',
        data: regressionLine.value,
      });
    }

    return series;
  });

  const apexOptions = computed(() => {
    return {
      chart: {
        fontFamily: 'serif',
        fontsize: '16px',
        height: 350,
      },
      colors: ['#8B0000', '#620101'],
      markers: {
        size: [5, 0],
      },
      legend: {
        show: false,
      },
      yaxis: {
        title: {
          text: labels[y],
          style: {
            fontSize: '16px',
            fontWeight: '600',
          },
        },
        type: 'numeric',
        min: minYAxis.value,
        max: maxYAxis.value,
        tickAmount: 7,
        decimalsInFloat: 2,
      },
      xaxis: {
        title: {
          style: {
            fontSize: '16px',
          },
          text: labels[x],
        },
        type: 'numeric',
        min: minXAxis.value,
        max: maxXAxis.value,
        tickAmount: 10,
        decimalsInFloat: 2,
      },
    };
  });

  return {
    plotPoints,
    apexOptions,
    apexSeries,
  };
}
